import { createSelector } from 'reselect'

import { fullSystemStateSelector } from './fullFileFirstOrder'

export const accountSelector = createSelector(fullSystemStateSelector, ({ account }) => {
  return account ?? {}
})

export const accountSubscriptionsSelector = createSelector(accountSelector, ({ subscriptions }) => {
  return subscriptions ?? {}
})

export const proAccountSubscriptionSelector = createSelector(
  accountSubscriptionsSelector,
  ({ proSubscription }) => {
    return proSubscription ?? {}
  }
)

export const plottrAccountSubscriptionSelector = createSelector(
  accountSubscriptionsSelector,
  ({ plottrSubscription }) => {
    return plottrSubscription ?? {}
  }
)
