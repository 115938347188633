import * as fullFileFirstOrder from './fullFileFirstOrder'
import * as applicationStateFirstOrder from './applicationStateFirstOrder'
import * as attributesFirstOrder from './attributesFirstOrder'
import * as backupsFirstOrder from './backupsFirstOrder'
import * as beatsFirstOrder from './beatsFirstOrder'
import * as booksFirstOrder from './booksFirstOrder'
import * as cardsFirstOrder from './cardsFirstOrder'
import * as categoriesFirstOrder from './categoriesFirstOrder'
import * as charactersFirstOrder from './charactersFirstOrder'
import * as clientFirstOrder from './clientFirstOrder'
import * as customAttributesFirstOrder from './customAttributesFirstOrder'
import * as domEventsFirstOrder from './domEventsFirstOrder'
import * as errorFirstOrder from './errorFirstOrder'
import * as featureFlagsFirstOrder from './featureFlagsFirstOrder'
import * as fileFirstOrder from './fileFirstOrder'
import * as imageCacheFirstOrder from './imageCacheFirstOrder'
import * as imagesFirstOrder from './imagesFirstOrder'
import * as knownFilesFirstOrder from './knownFilesFirstOrder'
import * as licenseFirstOrder from './licenseFirstOrder'
import * as linesFirstOrder from './linesFirstOrder'
import * as notesFirstOrder from './notesFirstOrder'
import * as notificationsFirstOrder from './notificationsFirstOrder'
import * as permissionFirstOrder from './permissionFirstOrder'
import * as placesFirstOrder from './placesFirstOrder'
import * as projectFirstOrder from './projectFirstOrder'
import * as settingsFirstOrder from './settingsFirstOrder'
import * as tagsFirstOrder from './tagsFirstOrder'
import * as templatesFirstOrder from './templatesFirstOrder'
import * as testingAndDiagnosisFirstOrder from './testingAndDiagnosisFirstOrder'
import * as seriesFirstOrder from './seriesFirstOrder'
import * as undoFirstOrder from './undoFirstOrder'
import * as offlineFirstOrder from './offlineFirstOrder'
import * as secondOrder from './secondOrder'
import * as applicationStateThirdOrder from './applicationStateThirdOrder'
import * as charactersThirdOrder from './charactersThirdOrder'
import * as customAttributesThirdOrder from './customAttributesThirdOrder'
import * as knownFilesThirdOrder from './knownFilesThirdOrder'
import * as notesThirdOrder from './notesThirdOrder'
import * as placesThirdOrder from './placesThirdOrder'
import * as tagsThirdOrder from './tagsThirdOrder'
import * as templatesThirdOrder from './templatesThirdOrder'
import * as timelineThirdOrder from './timelineThirdOrder'
import * as uiThirdOrder from './uiThirdOrder'
import * as categoriesThirdOrder from './categoriesThirdOrder'
import * as booksThirdOrder from './booksThirdOrder'
import * as backupsThirdOrder from './backupsThirdOrder'
import * as cardsThirdOrder from './cardsThirdOrder'
import * as filterThirdOrder from './filterThirdOrder'
import * as outlineThirdOrder from './outlineThirdOrder'
import * as accountFirstOrder from './accountFirstOrder'

const allSelectors = {
  ...fullFileFirstOrder,
  ...applicationStateFirstOrder,
  ...attributesFirstOrder,
  ...backupsFirstOrder,
  ...beatsFirstOrder,
  ...booksFirstOrder,
  ...cardsFirstOrder,
  ...categoriesFirstOrder,
  ...charactersFirstOrder,
  ...clientFirstOrder,
  ...customAttributesFirstOrder,
  ...domEventsFirstOrder,
  ...errorFirstOrder,
  ...featureFlagsFirstOrder,
  ...fileFirstOrder,
  ...imageCacheFirstOrder,
  ...imagesFirstOrder,
  ...knownFilesFirstOrder,
  ...licenseFirstOrder,
  ...linesFirstOrder,
  ...notesFirstOrder,
  ...notificationsFirstOrder,
  ...permissionFirstOrder,
  ...placesFirstOrder,
  ...projectFirstOrder,
  ...settingsFirstOrder,
  ...tagsFirstOrder,
  ...templatesFirstOrder,
  ...testingAndDiagnosisFirstOrder,
  ...seriesFirstOrder,
  ...undoFirstOrder,
  ...offlineFirstOrder,
  ...secondOrder,
  ...applicationStateThirdOrder,
  ...charactersThirdOrder,
  ...customAttributesThirdOrder,
  ...knownFilesThirdOrder,
  ...notesThirdOrder,
  ...placesThirdOrder,
  ...tagsThirdOrder,
  ...templatesThirdOrder,
  ...timelineThirdOrder,
  ...uiThirdOrder,
  ...categoriesThirdOrder,
  ...booksThirdOrder,
  ...backupsThirdOrder,
  ...cardsThirdOrder,
  ...filterThirdOrder,
  ...outlineThirdOrder,
  ...accountFirstOrder,
}

export default allSelectors
