import { SET_ACCOUNT_INFO } from '../constants/ActionTypes'

const INITIAL_STATE = {}

const accountReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_ACCOUNT_INFO: {
      const { proSubscription, plottrSubscription } = action.info
      return {
        subscriptions: {
          proSubscription: proSubscription ?? null,
          plottrSubscription: plottrSubscription ?? null,
        },
      }
    }

    default:
      return state
  }
}

export default accountReducer
